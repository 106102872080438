import short from 'short-uuid'

export function encodeUserIdToAffiliateId(userId: string): string {
  const translator = short()
  return translator.fromUUID(userId)
}

export function decodeAffiliateIdToUserId(code: string): string {
  const translator = short()
  return translator.toUUID(code)
}
